import { VariantProps } from '@stitches/react';
import { pxToRem, styled } from '@genialcare/atipico-react';

export const Root = styled('textarea', {
  appearance: 'none',
  resize: 'none',
  caretColor: '$neutral500',
  width: '100%',
  padding: pxToRem(16),
  outline: 'none',
  fontFamily: '$open-sans',
  fontWeight: '$regular',
  fontSize: pxToRem(16),
  lineHeight: pxToRem(24),
  borderRadius: pxToRem(8),
  border: `${pxToRem(1)} solid transparent`,
  '&[aria-invalid=true]': {
    borderColor: '$red500',
  },
  '&[aria-disabled="true"]': {
    backgroundColor: '#f0f0f0',
    borderColor: '#DDD',
    color: '#999',
    cursor: 'not-allowed',
  },
  variants: {
    variant: {
      outline: {
        color: '$neutral400',
        backgroundColor: '$neutral0',
        borderColor: '$neutral300',
        '&::placeholder': {
          color: '$neutral300',
        },
        '&:hover:not([aria-invalid=true], [aria-disabled=true])': {
          color: '$purple500',
          borderColor: '$purple500',
        },
        '&:focus:not([aria-invalid=true], [aria-disabled=true])': {
          color: '$neutral500',
          borderColor: '$purple500',
        },
      },
      fill: {
        color: '$neutral400',
        borderColor: 'transparent',
        backgroundColor: '$neutral100',
      },
    },
  },
  defaultVariants: {
    variant: 'outline',
  },
});

export const TextErrorMessageRoot = styled('span', {
  marginTop: pxToRem(4),
  fontSize: pxToRem(13),
  fontFamily: '$open-sans',
  color: '$red500',
});

export type TextAreaVariants = VariantProps<typeof Root>;
