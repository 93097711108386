import { gql } from '@apollo/client';

export const ITEM_SCORE_FRAGMENT_WITH_PROTOCOL = gql`
  fragment ItemScoreWithProtocolFields on ItemScore {
    id
    score
    filledAutomatically
    status
    protocolItem {
      id
      code
      domain
      subdomain
      areaCode
      areaName
      description
      protocolId
    }
  }
`;
