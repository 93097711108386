import { globalCss } from '@genialcare/atipico-react';
import DMSansRegularWoff from 'assets/fonts/dm-sans-regular.woff';
import DMSansRegularWoff2 from 'assets/fonts/dm-sans-regular.woff2';
import DMSansBoldWoff from 'assets/fonts/dm-sans-bold.woff';
import DMSansBoldWoff2 from 'assets/fonts/dm-sans-bold.woff2';
import OpenSansRegularWoff from 'assets/fonts/open-sans-regular.woff';
import OpenSansRegularWoff2 from 'assets/fonts/open-sans-regular.woff2';
import OpenSansBoldWoff from 'assets/fonts/open-sans-bold.woff';
import OpenSansBoldWoff2 from 'assets/fonts/open-sans-bold.woff2';
import OpenSansSemiBoldWoff from 'assets/fonts/open-sans-semi-bold.woff';
import OpenSansSemiBoldWoff2 from 'assets/fonts/open-sans-semi-bold.woff2';

export const globalStyles = globalCss({
  body: {
    margin: 0,
    padding: 0,
    height: '100%',
    fontSize: '1rem',
  },
  'div#root': {
    height: '100%',
  },
  'html, body': {
    height: '100%',
    backgroundColor: '$neutral0',
  },

  '.dropdown': {
    visibility: 'hidden',
  },

  '#rich-text-editor .editor-shell': {
    margin: 0,
    maxWidth: '100%',

    '& .toolbar': {
      padding: '14px',
      height: 'auto',
      backgroundColor: '$neutral100',

      '& .toolbar-item + .chevron-down': {
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: 'center',
      },
    },

    '& .editor-container': {
      padding: '14px',
      overflowY: 'auto',
      maxHeight: '400px',

      '@md': {
        maxHeight: '740px',
      },
    },

    '& .Placeholder__root': {
      padding: '14px',
    },

    '& ul': {
      listStyle: 'circle',
    },

    '& ol': {
      listStyle: 'decimal',
    },

    '& .PlaygroundEditorTheme__listItemUnchecked:before': {
      backgroundImage: 'url(/assets/verbum/square.svg)',
    },

    '& .PlaygroundEditorTheme__listItemChecked:before': {
      backgroundImage: 'url(/assets/verbum/square-check.svg)',
    },
  },

  '@font-face': [
    {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      src: `local(''), url(${DMSansRegularWoff2}) format('woff2'), url(${DMSansRegularWoff}) format('woff')`,
    },
    {
      fontFamily: 'DM Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      src: `local(''), url(${DMSansBoldWoff2}) format('woff2'), url(${DMSansBoldWoff}) format('woff')`,
    },
    {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      src: `local(''), url(${OpenSansRegularWoff2}) format('woff2'), url(${OpenSansRegularWoff}) format('woff')`,
    },
    {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      src: `local(''), url(${OpenSansSemiBoldWoff2}) format('woff2'), url(${OpenSansSemiBoldWoff}) format('woff')`,
    },
    {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      src: `local(''), url(${OpenSansBoldWoff2}) format('woff2'), url(${OpenSansBoldWoff}) format('woff')`,
    },
  ],
  html: {
    fontSize: '$base03',
    fontFamily: '$open-sans',
    height: '100%',
  },
  '@md': {
    html: {
      fontSize: '$base04',
    },
  },
  '@lg': {
    html: {
      fontSize: '$base05',
    },
  },
  '@xl': {
    html: {
      fontSize: '$base05',
    },
  },
  '@2xl': {
    html: {
      fontSize: '$base05',
    },
  },
});

export const GlobalStyles = () => {
  globalStyles();

  return null;
};
