import { gql } from '@apollo/client';

export const LIST_PROTOCOL_ITEMS_BY_PROTOCOL_ID = gql`
  query listProtocolItemsByProtocolId($protocolId: ID!) {
    protocolItems(protocolId: $protocolId) {
      id
      description
      libraryObjective {
        id
        description
      }
    }
  }
`;
