import { styled, Flex as FlexDefault } from 'styled-system/jsx';
import * as Checkbox from '@radix-ui/react-checkbox';

export const Flex = styled(FlexDefault, {
  base: {
    w: {
      xs: '100%',
      md: '-webkit-fill-available',
      lg: '345px',
    },
  },
});

export const Root = styled(Checkbox.Root, {
  base: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    w: '100%',
    p: '16px 16px 16px 48px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'neutral.300',
    borderRadius: 'base',
    bg: 'neutral.0',
    color: 'neutral.400',

    '&[aria-invalid=true]': {
      borderColor: 'red.500',

      _before: {
        borderColor: 'red.500',
      },
    },

    '&[aria-checked=true]': {
      _before: {
        borderColor: 'green.500',
        bg: 'green.500',
        color: 'neutral.0',
      },

      _after: {
        display: 'block',
      },
    },

    '&:disabled': {
      bg: 'neutral.200',
      opacity: 0.5,
      cursor: 'not-allowed',
    },

    '&[aria-checked=true]:disabled, &[aria-checked=false]:disabled': {
      _before: {
        borderColor: 'neutral.200',
        bg: 'neutral.300',
      },
    },

    _before: {
      content: '""',
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '16px',
      m: 'auto',
      w: '20px',
      h: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '12px',
      borderRadius: '4px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'neutral.300',
      backgroundColor: 'neutral.0',
    },

    _after: {
      content: '""',
      display: 'none',
      position: 'absolute',
      left: '23px',
      mt: '-3px',
      w: '7px',
      h: '10px',
      rotate: '45deg',
      borderBottomWidth: '2px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'neutral.0',
      borderRightWidth: '2px',
      borderRightStyle: 'solid',
      borderRightColor: 'neutral.0',
    },
  },
});

export const Label = styled('label', {
  base: {
    w: '100%',
  },
});

export const LabelText = styled('span', {
  base: {
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
  },
});

export const TextErrorMessageRoot = styled('span', {
  base: {
    mt: '4px',
    fontSize: '13px',
    fontFamily: 'open-sans',
    color: 'red.500',
  },
});
