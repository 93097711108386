import React, { useState } from 'react';
import { CSS, Typography } from '@genialcare/atipico-react';
import * as S from './TextArea.styles';
type Omitted = 'disabled' | 'readOnly';

type BaseProps = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, Omitted>;

export type TextAreaProps = BaseProps & {
  css?: CSS;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  showCounter?: boolean;
  rows?: number;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};
export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const {
    rows = 2,
    isInvalid = false,
    isDisabled = false,
    isReadOnly = false,
    showCounter = false,
    maxLength = 9999,
    autoComplete = 'off',
    onChange,
    ...rest
  } = props;

  const [remainingCharacters, setRemainingCharacters] = useState<number>(maxLength);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const currentLength = e.target.value?.length ?? 0;
    setRemainingCharacters(maxLength - currentLength);
    onChange?.(e);
  };

  return (
    <>
      <S.Root
        {...rest}
        ref={ref}
        rows={rows}
        disabled={isDisabled}
        readOnly={isReadOnly}
        aria-disabled={isDisabled}
        aria-invalid={isInvalid}
        aria-readonly={isReadOnly}
        onChange={handleChange}
        maxLength={maxLength}
        autoComplete={autoComplete}
      />
      {showCounter && (
        <Typography variant="subtitle" css={{ alignSelf: 'flex-start' }}>
          Quantidade de caracteres restantes: {remainingCharacters}
        </Typography>
      )}
    </>
  );
});
