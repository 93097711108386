import { gql } from '@apollo/client';

export const UPDATE_PEI_TARGET_STATUS = gql`
  mutation updatePEITargetStatus($target: UpdateTargetStatusInput!, $clinicalCaseId: ID!) {
    updatePeiTargetStatus(target: $target, clinicalCaseId: $clinicalCaseId) {
      id
      status
    }
  }
`;
