import { gql } from '@apollo/client';

export const GET_CREATE_OBJECTIVE_FORM_DATA = gql`
  query GetCreateObjectiveFormData(
    $eligibleDiscipline: String
    $protocolId: ID
    $isVinelandProtocol: Boolean!
  ) {
    protocols(eligibleDiscipline: $eligibleDiscipline) {
      id
      name
    }
    protocolItems @include(if: $protocolId, protocolId: $protocolId) {
      id
      description
      code
      domain
      subdomain
      libraryObjective {
        id
        description
        currentEvolutionCheckConfigurationId
      }
    }
    skills @include(if: $isVinelandProtocol) {
      id
      name
    }
  }
`;
