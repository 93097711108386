import { gql } from '@apollo/client';

export const GET_PROTOCOL_ITEM = gql`
  query GetProtocolItem($id: ID!) {
    protocolItem(id: $id) {
      protocolId
      libraryObjective {
        id
        description
        libraryStrategies {
          id
          abbreviation
          name
        }
      }
    }
  }
`;
