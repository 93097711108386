export enum ItemScoresStatuses {
  Selected = 'SELECTED',
}

export type VinelandProtocolItem = {
  id: string;
  code: number;
  domain: string;
  areaCode?: string;
  areaName?: string;
  subdomain: string;
  description: string;
  protocolId: string;
};

export type VinelandScoreItem = {
  id: string;
  score: string;
  filledAutomatically: boolean;
  protocolItem: VinelandProtocolItem;
  status?: ItemScoresStatuses;
};

export type VinelandPeiSuggestionItem = {
  subdomain: string;
  reason: string;
};

export type VinelandPeiSuggestions = {
  suggestions?: VinelandPeiSuggestionItem[];
};

export type VinelandReport = {
  id: string;
  interviewRespondentName: string;
  assessedAt: string;
  itemScores?: VinelandScoreItem[];
  domainScores?: VinelandDomainScore[];
  peiSuggestions: VinelandPeiSuggestions;
};

export type VinelandSubdomainScore = {
  id: string;
  subdomain: string;
  ageEquivalent: string;
  vScaleScore: number;
  gsv: number;
};

export type VinelandDomainScore = {
  id: string;
  domain: string;
  standardScore: number;
  subdomainScores: VinelandSubdomainScore[];
};
