import React, { useMemo, forwardRef, HTMLAttributes } from 'react';
import type { CSS, ThemeColors } from '@genialcare/atipico-react';
import * as S from './Checkbox.styles';

type CheckboxProps = HTMLAttributes<HTMLInputElement> & {
  css?: CSS;
  colorScheme?: ThemeColors;
  checked?: boolean;
  disabled?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ colorScheme = 'green500', css, checked, disabled, ...props }, ref) => {
    const colorSchemeStyle = useMemo(() => S.colorSchemeStyle(colorScheme), [colorScheme]);

    return (
      <S.Root
        {...props}
        ref={ref}
        css={css}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        className={colorSchemeStyle}
      />
    );
  },
);
