import { EvolutionCheckConfiguration, ObjectiveEvolutionCheck } from './evolutionCheck';

export enum ObjectiveStatus {
  'pending' = 'Pendente',
  'validated' = 'Validado',
  'completed' = 'Concluído',
  'rejected' = 'Rejeitado',
}

export enum TargetStatus {
  'baseline' = 'Linha de base',
  'on_hold' = 'Em espera',
  'ready' = 'Pronto',
  'in_treatment' = 'Ativo',
  'mastered' = 'Concluído',
  'in_maintenance' = 'Manutenção',
  'closed' = 'Suspenso',
}

export enum RegisterType {
  'anecdotal' = 'Qualitativo',
  'trial' = 'Tentativa',
  'duration' = 'Duração',
  'frequency' = 'Frequência',
  'taskAnalysis' = 'Análise de Tarefas',
  'occurrence' = 'Ocorrência',
}

export enum Disciplines {
  'ABA',
  'Fono',
  'TO',
}

export enum ProgramType {
  SYMBOLIC_PLAY = 'SYMBOLIC_PLAY',
  ABA = 'ABA',
  Fono = 'Fono',
  TO = 'TO',
}

export enum ProtocolNames {
  Vineland = 'Vineland 3',
  SymbolicPlay = 'Symbolic Play',
}

export interface TargetData {
  previousRegisters?: string[];
  lastPromptId?: string;
  currentPromptId: string;
  currentPromptName: string;
  requests?: InputTargetMeasurementRequest[];
}

export interface Target {
  id: string;
  name: string;
  status: keyof typeof TargetStatus;
  data: TargetData;
  completeTrials: number;
  createdAt: string;
}

export interface TargetCategory {
  name: string;
}

export interface Activity {
  name: string;
}

export interface Prompt {
  id: string;
  name: string;
  abbreviation: string;
  level?: number;
}

export interface PromptSchedule {
  id: string;
  name: string;
  promotion: number;
  demotion: number;
  sessionInARowToConsider: number;
  prompts: Prompt[];
}

export interface TrialConfiguration {
  promptSchedule: PromptSchedule;
}

type AsTrialConfiguration<T extends RegisterType> = T extends RegisterType.trial
  ? TrialConfiguration
  : never;

export interface TaskAnalysisStep {
  id: string;
  name: string;
  level: number;
}
export interface TaskAnalysisConfiguration {
  steps: TaskAnalysisStep[];
}

type AsTaskAnalysisConfiguration<T extends RegisterType> = T extends RegisterType.taskAnalysis
  ? TaskAnalysisConfiguration
  : never;

export type Configuration<T extends RegisterType> =
  | AsTrialConfiguration<T>
  | AsTaskAnalysisConfiguration<T>;

export const isTrialConfiguration = (
  configuration: Configuration<RegisterType> | undefined,
): configuration is Configuration<RegisterType.trial> => 'promptSchedule' in (configuration || {});

export const isTaskAnalysisConfiguration = (
  configuration: Configuration<RegisterType> | undefined,
): configuration is Configuration<RegisterType.taskAnalysis> => 'steps' in (configuration || {});

export interface Program {
  id: string;
  name: string;
  instruction: string;
  discipline?: keyof typeof Disciplines;
  programType?: keyof typeof ProgramType;
  stimulusDiscrimination?: string;
  consequence?: string;
  correctionProcedure?: string;
  independentResponseReinforcement?: string;
  promptedResponseReinforcement?: string;
  errorCorrection?: string;
  trialsPerSession: number;
  registerType: keyof typeof RegisterType;
  configuration?: Configuration<RegisterType>;
  targetCategory: TargetCategory;
  activities?: Activity[];
  targets?: Target[];
  sensorialFunctions?: InterventionProgramSensorialFunction[];
  strategies?: InterventionProgramStrategy[];
  skills?: Skill[];
  description?: string;
}
export interface TrialProgram extends Program {
  configuration: Configuration<RegisterType.trial>;
}

export interface Skill {
  id: string;
  name: string;
}

export interface VinelandItem {
  id: string;
  description?: string;
  code: string;
  domain: string;
  subdomain: string;
}

export interface Protocol {
  id: string;
  name: string;
  items?: ProtocolItem[];
}

export interface ProtocolItem {
  id: string;
  protocolName?: string;
  protocolId?: string;
  description?: string;
  code?: number;
  domain?: string;
  subdomain?: string;
  libraryObjective?: LibraryObjective;
}

export interface PerformanceComponent {
  name: string;
}

export interface Objective {
  id: string;
  description: string;
  status: keyof typeof ObjectiveStatus;
  skill: Skill;
  program: Program;
  programs?: Program[];
  strategies?: Strategy[];
  evolutionChecks?: ObjectiveEvolutionCheck[];
  protocolItem?: ProtocolItem;
  updatedAt: string;
  createdAt: string;
}

export interface PEI {
  id: string;
  version: number;
  objectives: Objective[];
}

export interface TrialRequestTargetMeasurementData {
  promptName?: string;
  promptId: string;
  observation?: string;
}

export interface FrequencyRequestTargetMeasurementData {
  frequencyCount: number;
}

export interface AnecdotalRequestTargetMeasurementData {
  note: string;
}

export interface OccurrenceRequestTargetMeasurementData {
  occurred: boolean;
}

export enum TaskAnalysisValue {
  Sim = 'true',
  Nao = 'false',
}

export enum OccurrenceValue {
  Sim = 'true',
  Nao = 'false',
}

export interface TaskAnalysisStepRequest {
  stepId: string;
  correct: boolean;
}

export interface TaskAnalysisRequestTargetMeasurementData {
  steps: TaskAnalysisStepRequest[];
}

export interface DurationRequestTargetMeasurementData {
  duration: string;
}

export interface InputTargetMeasurementRequest {
  id?: string;
  softDeleted?: boolean;
  measuredAt?: string;
  customTargetListId?: string;
  trial?: TrialRequestTargetMeasurementData;
  frequency?: FrequencyRequestTargetMeasurementData;
  anecdotal?: AnecdotalRequestTargetMeasurementData;
  taskAnalysis?: TaskAnalysisRequestTargetMeasurementData;
  duration?: DurationRequestTargetMeasurementData;
  occurrence?: OccurrenceRequestTargetMeasurementData;
}

export enum InterventionProgramStrategyStatus {
  'BASELINE' = 'Linha de base',
  'CLOSED' = 'Suspenso',
  'IN_MAINTENANCE' = 'Manutenção',
  'IN_TREATMENT' = 'Ativo',
  'DONE' = 'Concluído',
  'ON_HOLD' = 'Em espera',
}

export interface LibraryObjective {
  id: string;
  description: string;
  libraryStrategies: Strategy[];
  currentEvolutionCheckConfiguration?: EvolutionCheckConfiguration;
}

export interface Strategy {
  id: string;
  name: string;
  definition: string;
  useFor: string[];
  itsGoodFor: string[];
  abbreviation: string;
}

export interface InterventionProgramStrategy {
  id: string;
  name: string;
  strategyId: string;
  abbreviation: string;
  status: InterventionProgramStrategyStatus;
  createdAt: string;
  updatedAt: string;
}

export interface InterventionProgramSensorialFunction {
  id: string;
  name: string;
}
