import React, { PropsWithChildren, useEffect } from 'react';
import { Header } from '../Header';
import { useUserData } from '../../contexts/user';
import { useAuthenticatedUser } from '../../contexts/authenticatedUser';
import { SideMenu } from 'components/SideMenu';
import * as S from './Layout.styles';
import { Loading } from 'components/Loading';
import { FileCloudOff } from '@genialcare/atipico-react';
import { useNetworkAvailability } from 'contexts/networkAvailability';
import { useToast } from 'contexts/toast';

export type LayoutProps = PropsWithChildren & {
  loading?: boolean;
};

export const Layout: React.FC<LayoutProps> = ({ loading = false, children }) => {
  const toast = useToast();
  const { isOffline } = useNetworkAvailability();
  const user = useUserData();
  const authenticatedUser = useAuthenticatedUser();

  useEffect(() => {
    if (isOffline) {
      toast.error(
        'Parece que você está sem conexão à internet. Lembre-se de conectar novamente sua internet antes de enviar dados.',
        {
          duration: 3000,
          Icon: FileCloudOff,
        },
      );
    }
  }, [isOffline]);

  return (
    <S.Root>
      <SideMenu isClinicalCaseOwner={authenticatedUser.isClinicalCaseOwner} />

      <Header user={user} partnerInfo={authenticatedUser.partnerInfo} shadow />

      {loading ? <Loading /> : children}
    </S.Root>
  );
};
